import React, { useEffect, useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/hrbfpagelayout"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      formId {
        stayInformed
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const SponsorRegistration = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  useLayoutEffect(() => {
    function loadScript() {
      var bz = document.createElement("script")
      bz.type = "text/javascript"
      bz.async = true
      bz.src = "https://organizer.bizzabo.com/widgets/tickets/tickets.js"
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(bz, s)
    }
    loadScript()
  }, [])
  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      // registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={`Sponsor Registration | ${props?.data?.contentfulEvents?.seoMetadata?.title}`}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="registration_body">
        <h2 className="registration_title">Sponsor Registration</h2>
        {props?.data?.contentfulEvents?.slug === "kafka-summit-london-2024" && (
          <>
            <div className="registration_note">
              In order to view available tickets and register for Kafka Summit
              London 2024, please apply your unique sponsorship Promo Code
              below. Once you have applied your promo code, you will be able to
              register for a ticket.
            </div>
            <div className="registration_main">
              <div
                class="bizzabo-tickets-widget"
                data-event-id="559905"
                data-params="isInMinisite=true&widgetId=402290"
              ></div>
            </div>
          </>
        )}
        {/* {props?.data?.contentfulEvents?.slug ===
          "kafka-summit-bangalore-2024" && (
          <div className="registration_note">
            Registration will be opening soon for this event
          </div>
        )} */}
        {props?.data?.contentfulEvents?.slug ===
          "kafka-summit-bangalore-2024" && (
          <>
            <div className="registration_note">
              In order to view available tickets and register for Kafka Summit
              Bangalore 2024, please apply your unique sponsorship Promo Code
              below. Once you have applied your promo code, you will be able to
              register for a ticket.
            </div>
            <div className="registration_main">
              <div
                class="bizzabo-tickets-widget"
                data-event-id="573863"
                data-params="isInMinisite=true&widgetId=425422"
              ></div>
            </div>
          </>
        )}
      </div>
    </PageLayout>
  )
}
export default SponsorRegistration
